export const faGrand = {
    prefix: 'fac',
    iconName: 'grand',
    icon: [
        16, 16,
        [],
        null,
        "M14.85 15.36C14.93 12.16 14.34 10.29 13.07 9.74C9.82 8.32 10.29 4.95 10.08 2.78C9.24 -0.49 1.55 -0.02 1.42 2.98C1.18 4.2 1.18 8.33 1.42 15.36L14.85 15.36Z"
    ]
};
export const faUpright = {
    prefix: 'fac',
    iconName: 'upright',
    icon: [
        16, 16,
        [],
        null,
        "M1.92 4.21C0.87 4.65 0.3 4.92 0.2 5.03C0.1 5.14 0.09 5.98 0.17 7.54L1.49 7.64L1.64 15.15L14.49 15.89L15.88 14.58L15.6 0.21L2.35 0.21L1.92 4.21Z"
    ]
};
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {faGrand, faUpright} from './../js/custom-icons/custom-icon-pack';
import {toast} from 'react-toastify';

toast.configure({
    position: toast.POSITION.BOTTOM_CENTER
});

library.add(
    faGrand,
    faUpright
);
dom.watch();


require('bootstrap/dist/js/bootstrap.bundle.js');
require('bootstrap/dist/css/bootstrap.css');
// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../css/styles.scss');
require('@fortawesome/fontawesome-free/css/all.css');
require('react-toastify/dist/ReactToastify.min.css');


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.


//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
